<template>
  <FormLabel v-if="data && (value !== undefined || rule)">
    <v-checkbox
      v-if="rule && checkbox"
      v-model="value"
      :label="labelSidePosition ? $translate(`${translationName}.${dataKey}`) : customLabel"
      :disabled="disabled"
      :error-messages="getErrors()"
      @update:model-value="$emit('updateData', $event)"
    />
    <v-switch
      v-else-if="rule && !checkbox"
      v-model="value"
      :label="labelSidePosition ? $translate(`${translationName}.${dataKey}`) : customLabel"
      color="success"
      :disabled="disabled"
      :error-messages="getErrors()"
      @update:model-value="$emit('updateData', $event)"
    />
    <div v-else>
      <v-chip
        v-if="value"
        :variant="$config.public.chips_variant || 'tonal'"
        color="success"
      >
      {{ customTrueText ? customTrueText : 'Oui' }}
      </v-chip>
      <v-chip
        v-else
        :variant="$config.public.chips_variant || 'tonal'"
        color="error"
      >
      {{ customFalseText ? customFalseText : 'Non' }}
        
      </v-chip>
    </div>
  </FormLabel>
</template>

<script lang="ts">
import formFieldsInit from '../../utils/formFieldsInit.vue'
import FormLabel from "./label.vue";

export default {
    components: {FormLabel},
    extends : formFieldsInit,
    props : {
        labelSidePosition : { type : Boolean, required : false },
        customLabel : { type : String, required : false, default : ""},
        customTrueText : { type : String, required : false, default : ""},
        customFalseText : { type : String, required : false, default : ""},
        checkbox : {type: Boolean, required : false},
        
    },
emits: ['updateData'],
    // mounted () {
    // if (this.value === null) {
    //     this.data[this.dataKey] = false
    // }
    // }
}
</script>
