<script lang="ts">
export default {
    name: 'InscriptionStep1',
    props: {
        data: { type: Object, required: true },
        validationRules : {type: Object, required : true},
        validation : {type: Object, required : true},
        translationName : { type : String, required : true },
    },
    data: () => ({
    }),

    methods: {
        validateStep () {
            this.$refs.globalinfo?.isValidated()
            let errors: any[] = []
            if (this.validation && this.validation.data) {
                if (this.validation.data.first_name)
                    errors = errors.concat(this.validation.data.first_name.$errors)
                if (this.validation.data.last_name)
                    errors = errors.concat(this.validation.data.last_name.$errors)
                if (this.validation.data.organization_id)
                    errors = errors.concat(this.validation.data.organization_id.$errors)
                if (this.validation.data.email)
                    errors = errors.concat(this.validation.data.email.$errors)
            }
            return !errors.length
        }
    },
}
</script>

<template>
    <div class="pa-0">
        <!-- @update-data="$emit('updateData', { [`organization`]: $event, [`organization_id`]: $event?.id || null })" -->

        <FormUsersSectionsGlobalInfo ref="globalinfo"
            :data :translation-name :is-form="true" :validation :validation-rules :hideSection="true"
            @update-data="$emit('updateData', $event)"
        >

    </FormUsersSectionsGlobalInfo>
    </div>
</template>
