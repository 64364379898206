<script lang="ts">
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
export default {
    name: 'InscriptionStep3',
    props: {
        data: { type: Object, required: true },
        validationRules : {type: Object, required : true},
        validation : {type: Object, required : true},
        translationName : { type : String, required : true },
        registerTried : { type : Boolean, required : false },
    },
    components: {
        VueHcaptcha,
    },
    emits: ['captchaVerified', 'updateData'],
    data: () => ({
        defaultApps : [],
    }),
    mounted () {
        this.defaultApps = this.data.access_requests
    },
    methods: {
        verifyCaptcha () {
            console.log('verifyCaptcha')
            this.$emit('captchaVerified', true)
        },
        updateData(index: string | number, app: any) {
            this.defaultApps[index] = app
            this.$emit('updateData', { [`access_requests`]: this.defaultApps })
        },
        validateStep () {
            const selected = this.data.access_requests.filter((access: { asked: any; text: string | any[]; }) => access.asked && access.text && access.text.length > 49 && access.text.length < 501)
            return !!selected.length

            // let errors: any[] = []
            // this.$refs.first_name?.triggerValidation()

            // let errors: any[] = []
            // if (this.validation && this.validation.data) {
            //     if (this.validation.data.first_name)
            //         errors = errors.concat(this.validation.data.first_name.$errors)
            //     if (this.validation.data.last_name)
            //         errors = errors.concat(this.validation.data.last_name.$errors)
            //     if (this.validation.data.organization_id)
            //         errors = errors.concat(this.validation.data.organization_id.$errors)
            //     if (this.validation.data.email)
            //         errors = errors.concat(this.validation.data.email.$errors)
            // }
            // return !errors.length
        }
    },
}
</script>

<template>
    <div class="pa-0" id="access-rights-register">
        <div class="font-weight-bold">
            Demande d'accès aux applications
            <span class="text-caption text-medium-emphasis font-italic">
                (Obligatoire)
            </span>
        </div>
         <InfoCard text="Choisir au moins une application. Il est possible de demander d'autres accès ultérieurement." level="danger"
            v-if="registerTried && !validateStep()"
         />
        <div class="text-caption text-medium-emphasis" v-else>
            Choisir au moins une application. Il est possible de demander d'autres accès ultérieurement.
        </div>

        <template v-for="(app, i) in defaultApps" :key="i">
            <FormYesno
                checkbox
                :no-label="true"
                data-key="asked"
                :custom-label="app.application.title"
                :validation="validation"
                :validation-has-index="i"
                :data="app"
                :translation-name="`access_request`"
                @update-data="updateData(i, { ...app, asked: $event })"
            />
            <template v-if="app.asked">
                <span class="font-weight-bold">Motivations pour accéder à l'application : {{ app.application.title }}</span> <span class="text-medium-emphasis text-caption font-italic">(Obligatoire)</span>
                <div class="text-caption"
                    :class="registerTried && ( (app.text && (app.text?.length < 50 || app.text?.length > 500)) || !app.text )? 'text-error' : 'text-medium-emphasis'"
                >Entre 50 et 500 caractères maximum.</div>
                <FormTextarea
                    :ref="`text${i}`"
                    :no-label="true"
                    data-key="text"
                    :validation="validation"
                    :validation-has-index="i"
                    :data="app"
                    :translation-name="`access_request`"
                    @update-data="updateData(i, { ...app, text: $event })"
                />
            </template>
        </template>

        <VueHcaptcha
            ref="hcaptcha"
            class="mt-3"
            :sitekey="$config.public.hcaptchaPublic"
            @verify="verifyCaptcha"
        />
    </div>
</template>
