<script lang="ts">
export default {
    name: 'InscriptionStep1',
    props: {
        data: { type: Object, required: true },
        validationRules : {type: Object, required : true},
        validation : {type: Object, required : true},
        translationName : { type : String, required : true },
    },
    data: () => ({
    }),

    methods: {
        validateStep () {
            return this.$refs.password?.isValidated()
        }
    },
}
</script>

<template>
    <div class="pa-0">
        <FormUsersSectionsResetPasswordInputs ref="password"
            :data
            :translation-name
            :hideSection="true"
            @update-data="$emit('updateData', $event)"
        />
    </div>
</template>
