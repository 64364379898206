<script lang="ts">
import formValidationInit from '~/packages/datakode/nuxt-datakode-utils/utils/validations/formValidationInit.vue'
export default {
    name: 'Inscription',
    extends: formValidationInit,
    setup() {
        definePageMeta({ layout: 'empty', middleware: ['check-is-user'] })
        useHead({
            title: 'Connexion',
            meta: [
                { name: 'description', content: 'Inscription' },
            ],
        })
        const runtimeConfig = useRuntimeConfig()
        const access_token = useCookie<string>('access_token', { domain: runtimeConfig.public.accessTokenDomain })
        return {
            access_token,
        }
    },
    emits : ['closeModal'],
    data: () => ({
        currentStep: 1,
        maxStep: 3,
        data: {},
        validationRules: {},
        visible: false,
        loadingForm: false,
        loadingSave: false,
        registerTried: false,
        captchaVerified : false,
    }),
    mounted () {
        !this.loadingForm && this.initRegisterForm()
    },
    methods: {
        updateStep (currentStep: number, nextStep: number) {
            // if (currentStep < nextStep) {
            //     if (!this.$refs[`step${currentStep}`] || this.$refs[`step${currentStep}`]?.validateStep()) {
            //         this.currentStep = nextStep
            //     }
            // } else {
                this.currentStep = nextStep
            // }
        },

        // TO DO : NEED TO LAUNCH ON APP ADMIN FROM CONFIG STORE
        initRegisterForm() {
            this.loadingForm = true
            this.$api.getData({
                url: `/my-profile/create`,
            }).then((res: { data: any; meta: { rules: any; }; }) => {
                this.data = res.data
                this.validationRules = res.meta.rules
                const keys = Object.keys(this.validationRules)
                keys.forEach((key) => {
                    this.validationRules[key] = this.validationRules[key].split('|')
                })

                this.loadingForm = false
            })
        },
        async save() {
            if (this.$refs[`step1`].validateStep() && this.$refs[`step2`].validateStep() && this.$refs[`step3`].validateStep()) {
                try {
                        this.loadingSave = true
                        this.$api.setRequest({
                            url: 'my-profile',
                            method: 'POST',
                            body: {
                                ...this.data,
                            },
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            }
                        }).then((res: { errors: any; }) => {
                            if (res?.errors) {
                                this.loadingSave = false
                            } else {
                                this.$emit('closeModal')
                                this.$showToaster({ title: 'Création de votre compte', text: `Votre compte est créé. Vous recevrez un e-mail lorsque vous serez autorisé à accéder aux applications`, color: 'success', icon: 'mdi-info-circle', timer: -1 })
                            }
                        })
                        .catch( () =>  {
                            console.log('error----------')
                        })
                }
                catch (error) {
                    this.loadingSave = false
                    this.$showToaster({ title: 'Échec de la connexion', text: `La connexion a échoué, veuillez vérifier votre identifiant ou mot de passe.`, color: 'error', icon: 'mdi-alert-circle', timer: -1 })
                }
            } else {
                this.registerTried = true
                this.$showToaster({ text : `Veuillez renseigner les champs obligatoires.`, color : "warning", icon : "mdi-alert-circle" })
            }
        },
    },
}
</script>

<template>
    <div class="pa-0">
        <client-only>
            <!-- <v-form
                validate-on="submit lazy"
                @submit.prevent="save"
            > -->
            <v-stepper non-linear alt-labels flat hide-actions>
                <v-stepper-header>
                    <v-stepper-item
                        value="1"
                        :complete="currentStep > 1"
                        :editable="currentStep === 1"
                        :error="currentStep > 1  && $refs[`step1`] && !$refs[`step1`].validateStep()"
                    >
                        Informations
                    </v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item
                    value="2"
                    :complete="currentStep > 2"
                    :error="currentStep > 2  && $refs[`step2`] && !$refs[`step2`].validateStep()"
                    :editable="currentStep === 2"
                    >
                        Mot de passe
                    </v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item
                    value="3"
                    :complete="currentStep > 3"
                    :editable="currentStep === 3"
                    >
                        Applications
                    </v-stepper-item>
                </v-stepper-header>
                </v-stepper>

                <!-- Étape {{ currentStep }} sur {{ maxStep }} -->
                <ModalsLoader :loading="loadingForm" />
                <template v-if="!loadingForm">
                    <MenuAppbarRegisterStep1 v-show="currentStep === 1" :data :validationRules :validation="v$" translationName="utilisateurs"
                        @update-data="updateData($event)" ref="step1"
                    />
                    <MenuAppbarRegisterStep2 v-show="currentStep === 2" :data :validationRules :validation="v$" translationName="utilisateurs"
                        @update-data="updateData($event)" ref="step2"
                    />
                    <MenuAppbarRegisterStep3 v-show="currentStep === 3" :data :validationRules :validation="v$" translationName="utilisateurs"
                        @update-data="updateData($event)" ref="step3" :registerTried @captchaVerified="console.log('verifyCaptcha2', $event); captchaVerified = $event"
                    />
                    <!--  :disable-next-button="$refs[`step${currentStep}`] && !$refs[`step${currentStep}`]?.validateStep()" -->
                    <MenuAppbarRegisterStepButtons :disable-save-button="!captchaVerified" :loading="loadingSave" :max-step="maxStep"
                        :current-step="currentStep"
                        @update-step="updateStep(currentStep, $event)"
                        @save="save"
                    />
                </template>

            <!-- </v-form> -->
        </client-only>
    </div>
</template>
