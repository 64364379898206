<script lang="ts">
export default {
    name: 'InscriptionButtons',
    props: {
        currentStep: { type: Number, required: true },
        maxStep: { type: Number, required: true },
        loading: { type: Boolean, required: false },
        disableSaveButton: { type: Boolean, required: false },
    },
    emits: ['updateStep', 'save'],
}
</script>

<template>
    <v-row class="mt-4">
        <v-col v-if="currentStep > 1" cols="6" class="py-0">
            <v-btn
                color="primary"
                variant="outlined"
                block
                @click="$emit('updateStep', currentStep - 1)"
            >
                Précédent
            </v-btn>
        </v-col>
        <v-col :cols="currentStep > 1 ? 6 : 12" class="text-right py-0">
            <v-btn
                v-if="currentStep === maxStep"
                :disabled="disableSaveButton"
                variant="flat" block color="primary"
                :loading="loading"
                @click.prevent="$emit('save')"
            >
                Créer le compte
            </v-btn>
            <v-btn
                v-else
                variant="flat" block color="primary"
                @click="$emit('updateStep', currentStep + 1)"
            >
                Suivant
            </v-btn>
        </v-col>
    </v-row>
</template>
